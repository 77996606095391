import { IColumnFields } from 'contentful-types'

import { BlockRenderer } from '../../../renderer/contentful/BlockRenderer'
import { withContentful } from '../../../renderer/contentful/withContentful'

import Column, { ColumnProps } from './Column'

const ContentfulColumn = withContentful<IColumnFields, ColumnProps>(Column, {
  id: (props) => props.fields.sectionId,
  children: (props) => {
    return <BlockRenderer block={props.fields?.components} fieldPath={'.components'} />
  },
  horizontalAlign: (props) => {
    return {
      xs: props.fields?.horizontalAlign?.toLowerCase() as Lowercase<IColumnFields['horizontalAlign']>,
      sm: props.fields?.smHorizontalAlign?.toLowerCase() as Lowercase<IColumnFields['horizontalAlign']>,
      md: props.fields?.mdHorizontalAlign?.toLowerCase() as Lowercase<IColumnFields['horizontalAlign']>,
      lg: props.fields?.lgHorizontalAlign?.toLowerCase() as Lowercase<IColumnFields['horizontalAlign']>,
      xl: props.fields?.xlHorizontalAlign?.toLowerCase() as Lowercase<IColumnFields['horizontalAlign']>,
    }
  },
})

export default ContentfulColumn
