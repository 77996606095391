import styled from 'styled-components'

export const EqualColumnHeightContainer = styled.div({
  display: 'flex',
  flexFlow: `column`,
  height: '100%',
  div: {
    flexBasis: 'auto',
  },
  '> div:not(.spacer)': {
    flexBasis: '100%',
    flexGrow: `1`,
    height: 'auto',
    '> div': {
      height: '100%',
    },
  },
})
