import { ReactNode } from 'react'
import { FlexGrid } from '@telus-uds/components-web'
import { mapDataAttrsToDataSet } from '../../../utils/dataAttributes'
import { EqualColumnHeightContainer } from './Styles'
import { ResponsiveProps } from '../../../utils/types/components'

type ColumnSpanOptions = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
type OffsetOptions = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11
type HorizontalAlignmentOptions = 'left' | 'center' | 'right'

export type ColumnProps = {
  id?: string
  xs?: ColumnSpanOptions
  sm?: ColumnSpanOptions
  md?: ColumnSpanOptions
  lg?: ColumnSpanOptions
  xl?: ColumnSpanOptions
  xsOffset?: OffsetOptions
  smOffset?: OffsetOptions
  mdOffset?: OffsetOptions
  lgOffset?: OffsetOptions
  xlOffset?: OffsetOptions
  children?: ReactNode
  horizontalAlign?: ResponsiveProps<HorizontalAlignmentOptions>
  equalColumnHeight?: boolean
}

const renderWithEqualHeight = (children: ReactNode): ReactNode => (
  <EqualColumnHeightContainer>{children}</EqualColumnHeightContainer>
)

const Column = ({
  id,
  xs = undefined,
  sm = undefined,
  md = undefined,
  lg = undefined,
  xl = undefined,
  xsOffset = undefined,
  smOffset = undefined,
  mdOffset = undefined,
  lgOffset = undefined,
  xlOffset = undefined,
  children = undefined,
  horizontalAlign = 'left',
  equalColumnHeight = false,
  ...rest
}: ColumnProps) => {
  const renderChildren = equalColumnHeight ? renderWithEqualHeight(children) : children

  return (
    <FlexGrid.Col
      testID={'allium-column-testid'}
      nativeID={id}
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      xsOffset={xsOffset}
      smOffset={smOffset}
      mdOffset={mdOffset}
      lgOffset={lgOffset}
      xlOffset={xlOffset}
      horizontalAlign={horizontalAlign}
      dataSet={mapDataAttrsToDataSet(rest)}
    >
      {renderChildren}
    </FlexGrid.Col>
  )
}

export default Column
